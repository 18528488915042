import { pmmHttpClient, pmvHttpClient } from '@/api/instances'
import {
  PRODUCT_MODEL_BOM_VALIDATION,
  PRODUCT_MODEL_LIST,
  PRODUCT_MODEL_LOAD,
  PRODUCT_MODEL_DETECT_ELEMENTS_CHANGES,
  VALIDATION_SETTINGS_GET,
  PRODUCT_MODEL_SETTINGS_SAVE,
  PRODUCT_MODEL_COUNTS, PRODUCT_MODEL_DOWNLOAD_COMPLETE
} from './endpoints'

// GET: TODO
export const downloadCompleteAutoBom = async (id = '') => pmvHttpClient({
  url: PRODUCT_MODEL_DOWNLOAD_COMPLETE.replace('{productModelIds}', id),
  method: 'GET',
  responseType: 'blob'
})

// GET: Product models
export const getProductModels = async () => pmmHttpClient({
  url: PRODUCT_MODEL_LIST,
  method: 'GET'
})

// GET: Product models
export const getProductModelsCounts = async (id = '', encodedBusinessNames = '') => pmvHttpClient({
  url: PRODUCT_MODEL_COUNTS.replace('{productModelIds}', id).replace('{encodedBusinessNames}', encodedBusinessNames),
  method: 'GET'
})

// GET: Product Model Validation Settings
export const getValidationSettings = async (pmEncodedBusinessName = '') => pmvHttpClient({
  url: VALIDATION_SETTINGS_GET.replace(':pmEncodedBusinessName', pmEncodedBusinessName),
  method: 'GET'
})

// GET: Product Model BOM Validation
export const getProductModelBOMValidation = async (productModelId = '') => pmvHttpClient({
  url: PRODUCT_MODEL_BOM_VALIDATION.replace(':productModelId', productModelId),
  method: 'GET'
})

// POST: Load product model
export const loadProductModel = async (pmEncodedBusinessName = '') => pmmHttpClient({
  url: PRODUCT_MODEL_LOAD.replace(':pmEncodedBusinessName', pmEncodedBusinessName),
  method: 'POST'
})

export const detectElementsChangeFromProductModel = async ({
  options = [],
  components = [],
  pmInfo = {},
  oldPmInfo = {}
} = {}) => pmmHttpClient({
  url: PRODUCT_MODEL_DETECT_ELEMENTS_CHANGES,
  method: 'POST',
  data: {
    options,
    components,
    pmInfo,
    oldPmInfo
  }
})

// POST: Save Product Model Settings
export const saveProductModelSettings = async ({
  encodedBusinessName = '',
  bomSettings = {},
  invalidChoicesSettings = {}
} = {}) => pmvHttpClient({
  url: PRODUCT_MODEL_SETTINGS_SAVE,
  method: 'POST',
  data: {
    encodedBusinessName,
    bomSettings,
    invalidChoicesSettings
  }
})
