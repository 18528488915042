export const RESULT_STATUS = Object.freeze({
  FAILED: { title: 'Failed', color: 'negative' },
  IN_PROGRESS: { title: 'In progress', color: 'secondary' },
  SUCCESS: { title: 'Success', color: 'positive' },
  PROCESSED: { title: 'Success', color: 'positive' },
  UNKNOWN: { title: 'Unknown', color: 'secondary' }
})

export const BOM_VALIDATION_RESULT_TYPES = Object.freeze({
  COMPONENT_VARIANT: { key: 'componentVariant', label: 'Component Variants' },
  SOLUTION_ELEMENT: { key: 'solutionElement', label: 'Solution Elements' },
  TRANSFORMATION: { key: 'transformation', label: 'Transformation' }
})

export const BOM_VALIDATION_ERROR_TYPES = Object.freeze({
  AMBIGUOUS: { key: 'ambiguousElements', label: 'Ambiguous' },
  INCOMPLETE: { key: 'incompleteElements', label: 'Incomplete' },
  UNUSED: { key: 'unusedElements', label: 'Unused' }
})

export const BOM_VALIDATION_ERROR_TYPES_MESSAGE = Object.freeze({
  INCOMPLETE: {
    key: 'warn',
    label: 'There may be more incomplete configurations than displayed. CORE has reached its computing limit.'
  }
})

export const BOM_VALIDATION_IN_PROGRESS = 'IN_PROGRESS'
export const BOM_VALIDATION_FAILED = 'FAILED'
export const BOM_VALIDATION_SUCCESS = 'SUCCESS'

export const CHOICE_RULE_WINDCHILL_LINK = process.env.VUE_APP_CHOICE_RULE_WINDCHILL_LINK
export const BOM_SHOW_AMBIGUOUS_LINK = process.env.VUE_APP_BOM_SHOW_AMBIGUOUS_LINK

export const VALIDATIONS_FILTERS = Object.freeze({
  NONE: { key: '', value: '' },
  USER: { key: 'creator', value: 'creator' },
  DATE: { key: 'date', value: 'date' }
})

export const BOM_VALIDATION_FILTER = Object.freeze({
  NONE: { key: '', value: '' },
  USER: { key: 'creator', value: '' },
  DATE: { key: 'date', value: '' }
})

export const TEST_CASES_VALIDATION_TYPES = Object.freeze({
  PRODUCT_MODEL: { key: 'PRODUCT_MODEL', label: 'Product Model', description: 'Select Product Model' },
  USER_EXPORT: { key: 'USER_EXPORT', label: 'On Demand Exports', description: 'Select a recent export' }
})

export const TEST_CASES_VALIDATION_FORM = Object.freeze({ productModel: {}, planningPeriod: '' })

export const TEST_CASES_FILTER = Object.freeze({
  DISPLAY_ALL: { key: 'DISPLAY_ALL', label: 'Display all', value: false },
  PINNED: { key: 'PINNED', label: 'Pinned cases', value: true }
})
